<template>
  <div>
    <!--Title Bar-->
    <title-bar :title-value="'DCDEE Facilities'" />

    <!-- Filter -->
    <v-toolbar flat dense>
      <!--  Filter Icon -->
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="400px">
          <v-card-text>
            <v-container fluid grid-list-sm>
              <!--Clear Filter Button -->
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>

                <!--License #-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="ffacid"
                    label="License #"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="ffacname"
                    label="Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--County-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="countiesStore.countiesValueList"
                    item-text="d_name"
                    item-value="id"
                    label="County"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fcounty"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Type-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.facilityTypes"
                    item-text="value"
                    item-value="id"
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    v-model="foptype"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Rating-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="ratingsStore.ratingsValueList"
                    item-text="d_facilityValue"
                    item-value="id"
                    label="Rating"
                    placeholder=" "
                    persistent-placeholder
                    v-model="starrating"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Status-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.facilityStatus"
                    item-text="value"
                    item-value="value"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fstatus"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!--Submit Button-->
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="facilitiesStore.facilities.data && facilitiesStore.facilities.data.length > 0"
      :showTotal="true"
      :currentPage="facilitiesStore.facilities.current_page"
      :lastPage="facilitiesStore.facilities.last_page"
      :total="facilitiesStore.facilities.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <v-list
        v-if="facilitiesStore.facilities.data && facilitiesStore.facilities.data.length > 0"
        class="py-2"
        three-line
      >
        <template v-for="(item, index) in facilitiesStore.facilities.data">
          <v-list-item :key="item.id" @click="detail(item.uuid)" class="my-1">
            <v-list-item-content>
              <v-list-item-title class="light-blue--text text--darken-4"
                ><span v-if="item.provider" class="green--text">{{ item.ffacid }}</span
                ><span v-else>{{ item.ffacid }}</span></v-list-item-title
              >
              <v-list-item-title class="light-blue--text text--darken-4"
                ><span v-if="item.provider" class="green--text">{{ item.ffacname }}</span
                ><span v-else>{{ item.ffacname }}</span></v-list-item-title
              >
              <v-list-item-title>{{ item.county.d_name }} County</v-list-item-title>
              <v-list-item-title
                class="text-caption grey--text"
                :class="$vuetify.theme.dark === false ? 'text--darken-1' : ''"
              >
                Modified: {{ $_formatTimestamp(item.updated_at) }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text
                ><span class="text-body-1">{{ item.foptype }}</span></v-list-item-action-text
              >
              <v-list-item-action-text
                ><span
                  v-if="item.fstatus === 'Active'"
                  class="text-body-1 font-weight-bold green--text text--lighten-1"
                  >ACTIVE </span
                ><span v-if="item.fstatus !== 'Active'" class="text-body-1 error--text"
                  >{{ item.fstatus }}
                </span></v-list-item-action-text
              >
              <v-rating
                v-if="
                  item.starrating === '1 STAR' ||
                    item.starrating === '2 STAR' ||
                    item.starrating === '3 STAR' ||
                    item.starrating === '4 STAR' ||
                    item.starrating === '5 STAR'
                "
                :value="$_switchRating(item.starrating)"
                color="yellow darken-2"
                readonly
                small
                background-color="grey lighten-1"
              >
              </v-rating>
              <span v-else>{{ item.starrating }}</span>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </template>
      </v-list>

      <!--No Recrods Found Message-->
      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center" color="green"
              >No Facilities Exist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import Pagination from "@/components/Pagination";
import TitleBar from "@/components/TitleBar";

const { mapFields } = createHelpers({
  getterType: "facilitiesStore/getFilterField",
  mutationType: "facilitiesStore/updateFilterField"
});

export default {
  name: "Facilities",
  components: {
    Pagination,
    TitleBar
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      countiesStore: state => state.countiesStore,
      facilitiesStore: state => state.facilitiesStore,
      valueListsStore: state => state.valueListsStore,
      ratingsStore: state => state.ratingsStore,
      progressStore: state => state.progressStore
    }),
    ...mapFields(["page", "ffacid", "ffacname", "fcounty", "foptype", "starrating", "fstatus"])
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      filterMenu: false
    };
  },
  methods: {
    async initialize(mode) {
      this.$store.dispatch("progressStore/set", true);

      /* If loading the first time, grab value lists needed */
      if (!mode) {
        await this.loadValueLists();
      }
      /* Pull facility list */
      const filters = this.$store.getters["facilitiesStore/getFilters"];
      await this.$store.dispatch("facilitiesStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      /* Grab counties for value list */
      const counties = this.$store.getters["countiesStore/getCountyValueList"];
      if (counties.length === 0) {
        await this.$store.dispatch("countiesStore/valueList");
      }
      /* Pull Provider/Facility rating value list */
      const type = {
        type: 2
      };
      await this.$store.dispatch("ratingsStore/valueList", type);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize(1);
    },

    clearFilterColumns() {
      this.ffacid = "";
      this.ffacname = "";
      this.fcounty = "";
      this.foptype = "";
      this.starrating = "";
      this.fstatus = "";
      this.page = 1;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (
        this.ffacid ||
        this.ffacname ||
        this.fcounty ||
        this.foptype ||
        this.starrating ||
        this.fstatus
      ) {
        return true;
      }
      return false;
    },

    detail(uuid) {
      this.$store.dispatch("facilitiesStore/currentIndex", uuid);
      this.$router.push({ name: "facility", params: { facilityUUID: uuid } });
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
